/** @jsx jsx */
import { jsx, Embed } from "theme-ui"

import React from "react"

import Img from "gatsby-image"

function SectionImage(sectionData) {
  return (
    <React.Fragment>
      {sectionData.items.map((image, index) => (
        <div sx={{ gridColumn: `span ${image.width}` }}>
          <Img
            key={index}
            fluid={image.image.localFile.childImageSharp.fluid}
          />
        </div>
      ))}
    </React.Fragment>
  )
}

function SectionVideo(sectionData) {
  return (
    <div sx={{ gridColumn: "span 12", mb: [4, 4, 5] }}>
      {sectionData.items.map((video, index) => (
        <Embed key={index} src={video.video_url.text} />
      ))}
    </div>
  )
}

const Slices = ({ sectionData, sectionType }) => {
  switch (sectionType) {
    case "image":
      return <SectionImage {...sectionData} />
    case "video":
      return <SectionVideo {...sectionData} />
    default:
      return null
  }
}

export default Slices
