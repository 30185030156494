/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Slices from "../components/Slices"

const Post = ({ data: { prismicPost } }) => {
  const { data } = prismicPost

  return (
    <Layout>
      <div>
        <Styled.h1 sx={{ mt: 0, fontWeight: "heading" }}>
          {data.title.text}
        </Styled.h1>
        <Styled.h3
          as="h2"
          sx={{
            color: "gray",
            fontWeight: "heading",
            fontFamily: "mono",
          }}
        >
          {data.quote.text}
        </Styled.h3>
        <div
          dangerouslySetInnerHTML={{ __html: data.content.html }}
          sx={{ fontSize: [3, 3, 4], mb: 5 }}
        />
        {(data.body || []).map((slice, index) => (
          <div
            key={index}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(12, 1fr)",
              rowGap: [4, 4, 5],
              columnGap: [3, 4, 4],
            }}
          >
            <Slices sectionType={slice.slice_type} sectionData={slice} />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        quote {
          text
        }
        content {
          html
        }
        body {
          ... on PrismicPostBodyImage {
            id
            slice_type
            items {
              width
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPostBodyVideo {
            id
            slice_type
            items {
              video_url {
                text
              }
            }
          }
        }
      }
    }
  }
`
